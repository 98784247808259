import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import './Statistics.css';
import { Chart } from "react-google-charts";
import { useState, useEffect } from 'react';

const restEndpoint = "https://aocd-backend.herokuapp.com/graph/";



function Statistics() {

    let responseData = {

        'status': 200,
        'message': 'OK',
        'molecular_weight': {
            'data': [
                ["Molecular Weight", "No. of Compounds "], ["100-200", 31], ["200-300", 59], ["300-400", 70], ["400-500", 66], ["500-600", 36], ["600-700", 32], ["700-800", 12], ["800-900", 7], ["900-1000", 21], ["1000-1100", 6], ["1100-1200", 6], ["1200-1300", 1], ["1400-1500", 1], ["1700-1800", 1]
            ]
        },
        'x_log_p3': {
            'data': [
                ["xLogP3", "No. of Compounds "], ["-8.15 - -5.15", 3], ["-5.15 - -2.15", 7], ["-2.15 - 0.85", 62], ["0.85 - 3.85", 132], ["3.85 - 6.85", 90], ["6.85 - 9.85", 45], ["9.85 - 12.85", 5], ["12.85 - 15.85", 2], ["21.85 - 24.85", 1]
            ]
        },
        'h_bond_donor': {
            'data': [
                ["H-bond Donor", "No. of Compounds "], ["0-2", 150], ["3-5", 78], ["6-8", 52], ["9-11", 36], ["12-14", 24], ["15-17", 7], ["21-23", 1], ["24-26", 1]
            ]
        },
        'h_bond_acceptor': {
            'data': [
                ["H-Bond Acceptor", "No. of Compounds "], ["0-4", 126], ["5-9", 119], ["10-14", 45], ["15-19", 31], ["20-24", 23], ["25-29", 2], ["30-34", 1], ["35-39", 1], ["40-44", 1]
            ]
        },
        'ld50': {
            'data': [
                ["LD50 Values (Mg/Kg)", "No. of Compounds "], ["2-501", 75], ["502-1001", 49], ["1002-1501", 16], ["1502-2001", 41], ["2002-2501", 26], ["2502-3001", 13], ["3002-3501", 5], ["3502-4001", 38], ["4002-4501", 5], ["4502-5001", 54], ["5002-5501", 1], ["5502-6001", 2], ["7502-8001", 6], ["9502-10001", 12], ["11002-11501", 1], ["11502-12001", 2]
            ]
        },
        'toxicity': {
            'data': [
                ["Toxicity Class", "No. of Compounds "], ["1", 1], ["2", 8], ["3", 40], ["4", 134], ["5", 139], ["6", 23], ["20", 1]
            ]
        },
        'class_of_compounds': {
            'data': [
                ["Class of Compounds", " "], ["Flavonoid", 100], ["Terpene", 34], ["Hydrolase", 28], ["Prenol Lipid", 26], ["Polyketide", 19], ["Tannin", 11], ["Phenolic Compound", 11], ["Alkaloid", 11], ["Saponin", 10], ["Glycoside", 8], ["Unsaturated Fatty Acid", 8], ["Fatty Acyls", 7], ["Steroids & Derivatives", 6], ["Cinnamic Acid & Derivatives", 6], ["Naphthoquinone Derivative", 5], ["Others", 59]
            ]
        },
        'year_of_publication': {
            'data': [
                ["Year of Publication", "No. of Compounds "], ["1980 - 1987", 4], ["1988 - 1995", 8], ["1996 - 2003", 16], ["2004 - 2011", 138], ["2012 - 2019", 143], ["2020 - 2021", 40]
            ]
        }

    }

    console.log(responseData)

    const [data1, setData1] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data2, setData2] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data3, setData3] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data4, setData4] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data5, setData5] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data6, setData6] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data7, setData7] = useState([["Molecular weight Range", "No. of compounds"]]);
    const [data8, setData8] = useState([["Molecular weight Range", "No. of compounds"]]);

    const getData1 = () => {
        setData1(responseData.molecular_weight.data)
    }

    const getData2 = () => {
        setData2(responseData.x_log_p3.data)
    }

    const getData3 = () => {
        setData3(responseData.h_bond_donor.data)
    }

    const getData4 = () => {
        setData4(responseData.h_bond_acceptor.data)
    }
    const getData5 = () => {
        setData5(responseData.ld50.data)
    }

    const getData6 = () => {
        setData6(responseData.toxicity.data)
    }

    const getData7 = () => {
        setData7(responseData.class_of_compounds.data)
    }

    const getData8 = () => {
        setData8(responseData.year_of_publication.data)
    }



    useEffect(() => {
        getData1()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData2()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData3()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData4()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData5()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData6()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData7()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData8()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="statistics">
            <div className="statistics-header">
                <Navbar />
                <div className='statistics-heading'>
                    <h1>Statistics</h1>
                </div>
            </div>
            <div className='statistics-content'>

                <div className="graph">
                    <h2 className="graph-title">Class of Compounds</h2>
                    <Chart
                        chartType="BarChart"
                        data={data7}
                        width="100%"
                        height="900px"
                    />
                    <h3 className="graph-title"><i>*Others</i></h3>
                    <p><i>Lactone - 4, Fatty acid methyl esters - 4, Fatty acid - 4, Diaryl heptanoids - 4, Coumarins and derivatives - 3, Antioxidant - 3, Phytosterol - 3,
                        Carotenoid - 3, chalconoid - 3, Peptide - 2, Indole - 2, Vitamin - 2, carbazole - 2, Megastigmane -2, Anthocyanin - 2, Carbonyl compound - 1,
                        Alkylamide, Sulfated polysaccharide derived, PhenylPropanoid, Pteridines and derivatives - 1,
                        Carboxylic acid and derivatives - 1, Cysteine and derivatives - 1, Xanthone - 1, Sulfoxides - 1, Polysaccharides - 1</i></p>
                </div>

                <div className="graph">
                    <h2 className="graph-title">Year of Publication</h2>
                    <Chart
                        chartType="PieChart"
                        data={data8}
                        width="100%"
                        height="400px"
                    />
                </div>

                <div className="graph">
                    <h2 className="graph-title">Molecular weight Range</h2>
                    <Chart
                        chartType="Bar"
                        data={data1}

                        width="100%"
                        height="400px"
                    />
                </div>

                <div className="graph">
                    <h2 className="graph-title">xLog P3</h2>
                    <Chart
                        chartType="Bar"
                        data={data2}

                        width="100%"
                        height="400px"
                    />
                </div>

                <div className="graph">
                    <h2 className="graph-title">H-Bond Donor</h2>
                    <Chart
                        chartType="Bar"
                        data={data3}

                        width="100%"
                        height="400px"
                    />
                </div>


                <div className="graph">
                    <h2 className="graph-title">H-Bond Acceptor</h2>
                    <Chart
                        chartType="Bar"
                        data={data4}

                        width="100%"
                        height="400px"
                    />
                </div>

                <div className="graph">
                    <h2 className="graph-title">LD50 Values</h2>
                    <Chart
                        chartType="Bar"
                        data={data5}

                        width="100%"
                        height="400px"
                    />
                </div>


                <div className="graph">
                    <h2 className="graph-title">Toxicity Class</h2>
                    <Chart
                        chartType="Bar"
                        data={data6}

                        width="100%"
                        height="400px"
                    />
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default Statistics;

