import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import './Downloads.css';
import CsvDownloadButton from 'react-json-to-csv'

function Downloads() {

    const [data, setData] = useState("");
    const [SMILESdata, setSMILESdata] = useState([]);


    useEffect(() => {
        function getData() {

            

            setData(JSON.parse(localStorage.getItem("data")));


        }

        getData()
    }, [])


    useEffect(() => {

        function getSmilesData() {

            let updatedData = [];
            


            data.forEach((values) => {
                let smiles = {
                    sno: "",
                    accession_number: "",
                    canonical_smiles: "",
                }

                smiles.sno = values.sno;
                smiles.accession_number = values.accession_no;
                smiles.canonical_smiles = values.canl_smiles;
                updatedData=[...updatedData, smiles]
            });
            setSMILESdata(updatedData)
        }
        if(data){
            getSmilesData()
        }        
    }, [data])






    return (
        <div className="downloads">
            <div className="downloads-header">
                <Navbar />
                <div className='downloads-heading'>
                    <h1>Downloads</h1>
                </div>
            </div>
            <div className='downloads-content'>
                <div className="download_col">
                    <h1>2D Structure</h1>
                    <h3>Download 2D Structure for all Compounds</h3>
                    <div className="download-format">
                        <a href={process.env.PUBLIC_URL + "/Images/2D_ZIP.zip"} download style={{ paddingLeft: "10px" }}>
                            <h5><i className="fa-solid fa-download" />SDF</h5>
                        </a>
                    </div>
                </div>
                <div className="stick"></div>
                <div className="download_col">
                    <h1>3D Structure</h1>
                    <h3>Download 3D Structure for all Compounds</h3>
                    <div className="download-format">
                        <a href={process.env.PUBLIC_URL + "/Images/3D_ZIP.zip"} download style={{ paddingLeft: "10px" }}>
                            <h5><i className="fa-solid fa-download" />SDF</h5>
                        </a>
                    </div>
                </div>
                <div className="stick"></div>
                <div className="download_col">
                    <h1>SMILES CSV</h1>
                    <h3>Download SMILES CSV Data for all Compounds</h3>
                    <div className="download-format">
                        <h5 className="csv-download"><i className="fa-solid fa-download" /><CsvDownloadButton data={SMILESdata} filename={"SMILES.csv"}>CSV</CsvDownloadButton></h5>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Downloads;
